@font-face {
  font-family: "Harvest";
  src: url("../public/fonts/harvestregular.ttf") format("truetype");
}


html {
  scroll-snap-type: y mandatory;
}

body {
  /* background-color: rgb(124, 122, 122); */
  background-size: cover;
  background-color: black;
}

.app {
  /* color: white; */
  height: 100vh;
  /* next two lines will center the div container rather than using margin auto */
  /* display: grid; */
  /* place-items: center; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.camera {
  position: -webkit-sticky;
  position: sticky;
  top: 0.5rem;
  margin-left: 72%;
  z-index: 1;
  height: 35px;
  width: 90px;
  background-color: rgb(0, 0, 0);
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
}

@media screen and (max-width: 500px) {
  .camera {
    display: none;
  }
}

.lense1 {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  background-color: rgb(56, 52, 52);
  border-radius: 50%;
}

.lense_eye1 {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 15px;
  width: 15px;
  background-color: #222222;
  border-radius: 50%;
}

.lense2 {
  position: absolute;
  top: 5px;
  right: 60px;
  height: 25px;
  width: 25px;
  background-color: rgb(56, 52, 52);
  border-radius: 50%;
}

.lense_eye2 {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 15px;
  width: 15px;
  background-color: #222222;
  border-radius: 50%;
}

.app_videos {
  position: relative;
  margin: auto;
  height: 800px;
  border-radius: 40px;
  /* scroll the container */
  overflow: scroll;
  width: 400px;
  scroll-snap-type: y mandatory;
  border-style: solid;
  border-width: 12px 8px 17px 8px;
  /* box-shadow: 5px 5px 5px 5px #1b1b1b; */
  background-color: #111111;
}

@media screen and (max-width: 500px) {
  .app_videos {
    border: none;
    border-radius: 0px;
    box-shadow: none;
    width: 100%;
    height: 100%;
  }
}

.app_header {
  background-color: #c0c0c0;
  /* position:fixed; */
  /* centre and position the header */
  display: flex;
  justify-content: center;
  top:0;
  width:100%;
  height: 50px;
  z-index:100;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.app_videos::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.app_videos {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: black;
}

.debug {
  letter-spacing: 1px;
  font-family: 'Harvest', sans-serif;
  position: fixed;
  left: 50%;
  bottom: 5%;
  color: #c0c0c0;
  /*background-color: white;*/
  margin: 0 auto;
  /* move to right side*/
  /*black background */
  /*background-color: black;*/
  transform: translateX(-50%); 
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
}


form {
  display: flex; /* display the input and button as a flex container */
  align-items: center; /* center the items vertically within the container */
  background-color: #f2f2f2; /* set the background color to match TikTok's style */
  /* //border-radius: 40px; round the corners of the container */
  padding: 8px 16px; /* add some padding to the container */
}

input[type="text"] {
  flex: 1; /* take up all remaining space within the container */
  border: none; /* remove the default border */
  background-color: transparent; /* remove the default background color */
  font-size: 16px; /* set the font size to match TikTok's style */
  padding: 8px; /* add some padding to the input */
}

search-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.ais-Hits-list{
  flex:1;
  background-color: #f2f2f2;
}


.ais-SearchBox {
  justify-content: center;
}

.MuiAppBar-colorPrimary {
    color: #fafafa;
    background-color: #56377c;
}

.MuiPaper-root,
.MuiAppBar-root {
  background-color: black;
}

.MuiAppBar-positionStatic.MuiAppBar-colorPrimary {
  background-color: #00af50;
}


.intro-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.intro-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.intro-page img {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
}

.intro-link {
  font-size: 20px; /* Decrease font size for slimmer buttons */
  font-family: 'Harvest', sans-serif;
  padding: 0px 8px; /* Adjust padding for slimmer buttons */
  background-color: #00af50;
  color: #000000;
  text-decoration: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin: 5px; /* Add margin for spacing between buttons */
}

.intro-links {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 2;
}

.intro-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


@media only screen and (min-width: 768px) {
  .intro-page {
    max-width: 600px;
  }
  
  .intro-links {
    flex-direction: row;
  }
  
  .intro-link {
    margin: 5px 10px;
  }
}

.logo-container {
  position: relative;
  z-index: 2; /* add z-index */
}

.logo {
  display: block;
  margin: auto;
  width: 200px;
  height: 200px;
  z-index: 2;
}

.circle-container {
  position: absolute;
  /* adjust height of cntre of circle */
  top: 150px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; /* lower z-index */
}

.circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  animation: animateCircle 3s ease-in-out infinite;
}

.circle:nth-child(2) {
  animation-delay: 1s;
}

.circle:nth-child(3) {
  animation-delay: 2s; /* increase delay */
}

@keyframes animateCircle {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(50);
    opacity: 0;
  }
}


.app_title {
  font-family: 'Harvest', sans-serif;
  font-size: 18px;  
  font-weight: bold;
  background-color: #00af50;
  color: black;
  /* hyperlink color */
  text-decoration: underline;
  text-align: center;
  margin: 10px 0;
}

.app_sub_title {
  letter-spacing: 1px;
  font-family: 'Harvest', sans-serif;
  font-size: 24px;
  color: #fafafafa;
  text-align: center;
  z-index:2;
}

.app_sub_title a {
  color: inherit;
  text-decoration: underline;
}

.tagline {
  font-family: 'Harvest', sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
   
  color: #fafafafa;
  text-align: center;
  margin: 10px 0;
  z-index:2;
}

.email { 
    text-align: center;
    font-family: 'Harvest', sans-serif;
    color: white;
    margin-top: 20px;
    z-index: 3;
}

.email a {
  color: white;
}

.favbutton button {
  font-family: 'Harvest', sans-serif;
  font-size: 20px;
  background-color: #00af50;
  color: #000000;
  text-decoration: none;
  border-radius: 5px;
  width: 100%;
}

.buybutton {
}

.cost-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cost-text {
  margin-right: 8px; /* Adjust this value to increase or decrease the space */
}

/*yuk*/
.player-wrapper {
  position: relative;
}

/*yuk*/
.overlay {
  position: absolute;
  top: 0;
  bottom: 34%;
  left: 0;
  right: 0;
}