.videoSidebar {
  position: absolute;
  color: white;
  top: 48%;
  right: 0;
}

@media screen and (max-width: 500px) {
  .videoSidebar {
    position: absolute;
    color: white;
    top: 42%;
    right: 0;
  }
}

.videoSidebar_button {
  padding: 20px;
  text-align: center;
}
