.videoFooter {
  position: absolute;
  color: white;
  bottom: 30%;
  margin-left: 15px;
  display: flex;
  border-radius: 10px;
}


@media screen and (max-width: 767px) {
  .videoFooter {
    position: absolute;
    bottom: 30%;
    margin-left: 15px;
    display: flex;
  }
}

.videoFooter_text {
  flex: 1;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
}

.videoFooter_text > p {
  padding-bottom: 10px;
  width: 90%;
}

@media screen and (max-width: 500px) {
  .videoFooter_text > p {
    width: 85%;
  }
}

@keyframes spinTheRecord {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.videoFooter_record {
  animation: spinTheRecord infinite 5s linear;
  height: 50px;
  filter: invert(1);
  position: absolute;
  bottom: 0;
  right: 10px;
}

.videoFooter_icon {
  position: absolute;
}

.videoFooter_ticker > p {
  height: fit-content;
  margin-left: 30px;
  width: 70%;
}

@media screen and (max-width: 500px) {
  .videoFooter_ticker > p {
    margin-left: 25px;
    /* width: 60%; */
  }
}

.videoFooter_text > h3 {
  padding-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .videoFooter_text > h3 {
    padding-bottom: 0px;
  }
}

.videoFooter_ticker h1 {
  padding-top: 7px;
}
