.video {
  position: relative;
  /*background-color: whitesmoke;*/
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
}

.tooltip {
  z-index: 10;
}